<template>
    <div v-if="content" class="podcast-episode">
        <div class="left-content content-col">
            <slot name="leftContent">
                <TypeLabel v-if="content.label" :name="content.label" class="type-label" />
                <Anchor :url="content.url">
                    <Typography v-if="content.title" as="h2" variant="h2-title">
                        {{ content.title }}
                    </Typography>
                </Anchor>
                <template v-if="isPrimary">
                    <RichTextElements
                        v-if="content.description"
                        bodyStyle="serif-large"
                        :elements="content.description"
                        class="dek"
                    />
                    <div class="last-row credits">
                        <AvatarSet v-if="content.people?.length" :people="content.people" />
                        <Typography v-if="publicationDate.length" variant="body-micro" class="publication-date"
                            >· {{ publicationDate }}</Typography
                        >
                    </div>
                </template>
            </slot>
        </div>

        <div class="right-content content-col">
            <slot name="rightContent">
                <template v-if="isPrimary">
                    <Image v-bind="content.image" class="image" />
                </template>
                <template v-else>
                    <RichTextElements
                        v-if="content.description"
                        bodyStyle="serif-large"
                        :elements="content.description"
                        class="dek"
                    />
                    <div class="last-row credits">
                        <AvatarSet v-if="content.people?.length" :people="content.people" />
                        <Typography v-if="publicationDate.length" variant="body-micro" class="publication-date"
                            >· {{ publicationDate }}</Typography
                        >
                    </div>
                </template>
            </slot>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    podcastEpisode: {
        type: Object,
    },

    isPrimary: {
        type: Boolean,
        default: false,
    },
    newSearch: {
        type: Boolean,
        default: false,
    },
});

const content = computed(() => {
    if (props.newSearch === true) {
        return props.podcastEpisode;
    }

    const contentType = props.podcastEpisode?.contentType;
    if (contentType) {
        return getContentTypeParsers(props.podcastEpisode)[contentType]?.();
    }
});
const publicationDate = computed(() => {
    return formatDate(props.podcastEpisode?.publishedAt, 'MMM. d, y');
});
</script>

<style lang="scss" scoped>
.podcast-episode {
    @include padding-tb(vertical-space(1));
    @include media-query(not-phone) {
        @include grid;
        gap: var(--col-gutter);
    }
    @include content-area;
}

.left-content {
    @include media-query(phone) {
        margin-bottom: vertical-space(1);
    }

    @include media-query(not-phone) {
        grid-column: 1 / span 8;
    }

    @include media-query(full) {
        grid-column: 1 / span 7;
    }
}

.right-content {
    @include media-query(phone) {
    }

    @include media-query(not-phone) {
        grid-column: 9 / span 4;
    }
}

.type-label {
    margin-bottom: vertical-space(0.5);
}

.dek {
    color: color(noir, default, 0.4);
}

.last-row {
    display: inline-flex;
    align-items: center;
    gap: 0.4rem;
    max-width: 100%;
}

.publication-date {
    color: color(offnoir, default, 0.6);
    position: relative;
    top: 0.15rem;
}

.publication-date {
    white-space: nowrap;
    top: 0.15rem;
}

.credits {
    margin-top: vertical-space(0.5);
}
</style>
